<template>
	<form name="contact" class="form contact-form" v-on:submit.prevent.stop="sendContact(contact.data)">

		<div class="form-field" :class="{'has-error':errors.has('name'),'has-data':hasData('name'),'has-focus':hasFocus('name')}">
			<label for="name">Your Name<span>*</span></label>
			<input type="text" class="form-control" name="name" id="name" v-model="contact.data.name" autocomplete="name" v-validate="'required'" v-on:focus="doFocus('name',true)" v-on:blur="doFocus('name',false)" required>
			<div class="form-msg" v-if="errors.has('name')">{{errors.first('name')}}</div>
		</div>

		<div class="form-field" :class="{'has-error':errors.has('email'),'has-data':hasData('email'),'has-focus':hasFocus('email')}">
			<label for="email">Your Email<span>*</span></label>
			<input type="email" class="form-control" name="email" id="email" v-model="contact.data.email" autocomplete="email"  v-validate="'required|email'" v-on:focus="doFocus('email',true)" v-on:blur="doFocus('email',false)" required>
			<div class="form-msg" v-if="errors.has('email')">{{errors.first('email')}}</div>
		</div>

		<!-- <div class="form-field" :class="{'has-error':errors.has('company'),'has-data':hasData('company'),'has-focus':hasFocus('company')}">
			<label for="company">Your Company</label>
			<input type="text" class="form-control" name="company" id="company" v-model="contact.data.company" autocomplete="organization" >
		</div> -->

		<div class="form-field" :class="{'has-error':errors.has('phone'),'has-data':hasData('phone'),'has-focus':hasFocus('phone')}">
			<label for="phone">Your Phone<span>*</span></label>
			<input type="tel" class="form-control" name="phone" id="phone" v-model="contact.data.phone" autocomplete="tel"  v-validate="'required|digits:10'" required>
			<div class="form-msg" v-if="errors.has('phone')">{{errors.first('phone')}}</div>
		</div>

		<div class="form-field" :class="{'has-error':errors.has('website'),'has-data':hasData('website'),'has-focus':hasFocus('website')}">
			<label for="website">Artist Preference (if known)</label>
			<input type="url" class="form-control" name="website" id="website" v-model="contact.data.website" autocomplete="url"  v-validate="'url'">
			<div class="form-msg" v-if="errors.has('website')">{{errors.first('website')}}</div>
		</div>

		<div class="form-field form-field-full" :class="{'has-error':errors.has('description'),'has-data':hasData('description'),'has-focus':hasFocus('description')}">
			<label for="description">Briefly Tell Us About Your Idea<span>*</span></label>
			<textarea class="form-control" name="description" id="description" v-model="contact.data.description" maxlength="250" placeholder="Brief Description" v-validate="'required|max:500'" required></textarea>
			<div class="form-msg" v-if="errors.has('description')">{{errors.first('description')}}</div>
		</div>

		<div class="form-field form-field-submit">
			<button type="submit" class="btn line-sm" :disabled="contact.loading">Submit Form</button>
			<small>You'll hear from us in 1-2 business days.</small>
		</div>

		<div class="form-message" v-if="contact.success || contact.fail" :class="{'has-error':contact.fail,'has-success':contact.success}">
			{{contact.success || contact.fail}}
		</div>
	</form>
</template>

<script>
	// Dependencies
	import Vue from 'vue'
	import VeeValidate from 'vee-validate'
	import {mapState,mapGetters,mapActions} from 'vuex'

	// Install
	Vue.use(VeeValidate, {
		delay: 350,
		enableAutoClasses: false
	})

	// Export
	export default {
		name: 'contactForm',
		data: () => {
			return {
				// contact: {},
				focused: null
			}
		},
		mounted () {
			if( !this.$el.elements ) return
			Object.keys(this.$el.elements).forEach( key => {
				let el = this.$el.elements[key]
				el.addEventListener('focus', this.doFocus.bind(this,el.name,true) )
				el.addEventListener('blur', this.doFocus.bind(this,el.name,false) )
			})
		},
		methods: {
			hasData(field)
			{
				return this.contact.data[field]
			},
			hasFocus(field)
			{
				return this.focused === field
			},
			doFocus(field,state)
			{
				this.focused = state ? field : null
			},
			...mapActions([
				'sendContact'
			]),
		},
		computed: {
			...mapGetters([
				'contact'
			])
		}
	}
</script>

<style scoped lang="scss">

	.form {
		box-sizing: border-box;
		box-sizing: border-box;
		display: flex;
		flex-wrap: wrap;
		//padding: 30px;
		text-align: left;
		width: 100%;
		width: 100%;

		@media screen and (max-width: map-get($breakpoints, 'xl')) {
			padding: 10px;
		}

		@media screen and (max-width: map-get($breakpoints, 'md')) {
			padding: 0px;
		}

		.form-field {
			width: 50%;
			padding: 0 10px;

			@media screen and (max-width: map-get($breakpoints, 'lg')) {
				width: 100%;
			}

			&-full {
				width: 100%;
			}

			&-submit {
				width: 100%;
				text-align: center;
				justify-content: center;

				.btn {
					margin: 20px 0 20px;
					font-family: $font-primary;
				}
			}
		}
		&-field {
			//border: 1px solid gray;
			border-radius: $line-sm/2;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			margin: $line-sm 0;
			padding: 0;
			position: relative;
			width: 100%;

			label {
				// background: rgba(0,0,0,0.3);
				//transform: translate3d(0,50%,0) scale(1);
				//transform-origin: 0 50%;
				transition: all 250ms $easing-cubic;
				position: relative;
				//opacity: 0.7;
				padding: $line-sm 0;
				font-weight: 400;
				//font-size: 16px;

				span {
					color: color('accent');
					font-weight: 700;
				}
			}

			&.has-focus,
			&.has-data {
				label {
					//transform: translate3d(0,0,0) scale(0.8);
					opacity: 1;
					color: color('gray','medium');
				}
			}

			&.has-focus {
				border-color: color('accent');
				label {
					color: color('accent');
				}

				.form-control {
					border-color: color('gray','medium');
				}
			}

			&.has-error {
				border-color: color('alert','error');
				label {
					color: color('alert','error');
				}
			}
		}

		&-msg {
			@include font-size('small');
			//background-color: lighten(color('alert','error'),54);
			border: 1px solid gray;
			border-radius: $line-sm/2;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			bottom: 0;
			box-sizing: border-box;
			left: 10px;
			right: 0;
			margin-right: 10px;
			opacity: 0;
			padding: $line-sm;
			position: absolute;
			transform: translate3d(0,100%,0);
			transition: all 250ms $easing-cubic;
			z-index: 2;

			.has-focus & {
				opacity: 1;
			}
			.has-error & {
				border-color: color('alert','error');
				color: color('alert','error');
			}
		}

		&-control {
			// background: rgba(0,0,0,0.3);
			border: 0;
			box-shadow: 0;
			border-radius: 3px;
			outline: 0;
			padding: $rhythm / 2;
			// margin-bottom: $line-sm;
			font-size: 18px;
			border: 1px solid color('gray','light');
			font-family: $font-primary;
			font-weight: 500;
			transition: border $easing-cubic .4s;

			.focused & {
				border: 1px solid color('gray','dark');
			}

			&::placeholder {
				color: transparent;
				transition: color 250ms $easing-cubic;
				transition-delay: 0;

				.focused & {
					color: grey;
					transition-delay: 250ms;
				}
			}
		}

		&-message {
			text-align:center;
			width: 100%;
			font-family: $font-primary;
			margin: 10px auto;

			&.has-success {
				border-radius: 3px;
				background-color: color('alert','success');
				color: color('white');
			}
		}
	}
</style>
