<template>
	<div class="partial partial--home home">
		<hero id="home"></hero>
		<content-band id="about">
			<h2 class="content-band__title"><span>Award Winning Tattoos </span><br /> & Custom Art</h2>
			<p>Mike Parsons Ink is a world-class tattoo and custom art production studio located in Riverview, FL, owned by renowned tattoo artist Mike Parsons. Mike possesses over 25 years of experience in tattooing, illustration and art direction, having won countless awards and critical acclaim in the tattoo industry for his work. </p>
			<p>Along with a crew of handpicked and highly talented tattoo artists, Mike Parsons Ink offers the highest caliber of quality and professionalism in the industry.</p>
			<!-- <p class="text-center"><a href="#" class="btn">Read More</a></p> -->
		</content-band>
		<div class="home-artists-wrapper" id="artists">
			<artists></artists>
		</div>
		<content-band id="shop">
			<h2 class="content-band__title">The Shop</h2>
			<p>Mike Parsons Ink is a clean and professional tattoo studio that upholds the highest levels of hospital grade sterilization in a safe and comfortable environment. Located in Riverview, FL, about 20 minutes outside of Tampa, Mike Parsons Ink is a modern new school tattoo studio showcasing contemporary techniques, styles and safety precautions.</p>
			<div slot="img-bottom" class="content-band__image" :style="shopImage"></div>
		</content-band>
		<button-gallery v-on:click.native="show = !show" v-bind:class="{ 'button-gallery--open': show }"></button-gallery>
		<transition class="transition" name="fade" mode="out-in">
			<div class="artist-container" v-if="show">
				<artists></artists>
			</div>
		</transition>
	</div>
</template>

<script>
// Components
import Hero from '@/components/Hero'
import ContentBand from '@/components/ContentBand'
import Artists from '@/components/Artists'
import ButtonGallery from '@/components/ButtonGallery'


// Export
export default {
	name: 'home',
	components: {
		Hero,
		ContentBand,
		Artists,
		ButtonGallery,
	},
	data: () => ({
		shopImage: {
			backgroundImage: 'url(' + require('@/assets/images/home__shop.jpg') + ')'
		},
		show: false
	}),
	metaInfo() {
		return {
			title: 'Mike Parsons Ink',
		}
	},
}
</script>

<style lang="scss" scoped>
.artist-container {
		position: fixed;
		top: 0;
		left:0;
		right:0;
		bottom:0;
		z-index: 32;
		overflow: auto;
		transform: translateY(0);
		transition: transform 400ms $easing-material, opacity 200ms $easing-material;

		&.fade-enter-active {
			//transition: opacity .5s;
			top: 100%;
			transform: translateY(100%);
			
		}

		&.fade-leave-active {
			opacity: 0;
			transform: translateY(100%);
		}
	}
</style>
