<template>
		<div class="loader" :class="{'loader--loading':loading.state,'loader--mounted':mounted}">
			<div class="loader-content">
				<svg width="57" height="57" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" stroke="#5189CF">
							<g fill="none" fill-rule="evenodd">
								<g transform="translate(1 1)" stroke-width="2">
									<circle cx="5" cy="50" r="5">
										<animate attributeName="cy"
												begin="0s" dur="1.5s"
												values="50;5;50;50"
												calcMode="linear"
												repeatCount="indefinite" />
										<animate attributeName="cx"
												begin="0s" dur="1.5s"
												values="5;27;49;5"
												calcMode="linear"
												repeatCount="indefinite" />
									</circle>
									<circle cx="27" cy="5" r="5">
										<animate attributeName="cy"
												begin="0s" dur="1.5s"
												from="5" to="5"
												values="5;50;50;5"
												calcMode="linear"
												repeatCount="indefinite" />
										<animate attributeName="cx"
												begin="0s" dur="1.5s"
												from="27" to="27"
												values="27;49;5;27"
												calcMode="linear"
												repeatCount="indefinite" />
									</circle>
									<circle cx="49" cy="50" r="5">
										<animate attributeName="cy"
												begin="0s" dur="1.5s"
												values="50;50;5;50"
												calcMode="linear"
												repeatCount="indefinite" />
										<animate attributeName="cx"
												from="49" to="49"
												begin="0s" dur="1.5s"
												values="49;5;27;49"
												calcMode="linear"
												repeatCount="indefinite" />
									</circle>
								</g>
							</g>
						</svg>
			</div>
		</div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
	name: 'loader',
	data: () => ({
		mounted: false,
		delay: 2000,
	}),
	mounted ()
	{
		this.mounted = true
	},
	computed: {
		...mapGetters([
			'loading',
		])
	}
}
</script>

<style scoped lang="scss">
	.loader {
		align-items: center;
		background-color: color_('grey','dark');
		display: flex;
		flex-direction: column;
		height: 100vh;
		justify-content: center;
		left: 0;
		position: fixed;
		top: 0;
		text-align: center;
		transform: translate3d(-100%,0,0);
		transition: transform 500ms $easing-cubic 0ms;
		width: 100%;
		//will-change: transform;
		z-index: 900;

		&__number {
			color: color_('white');
			font-family: 'museo_sans900';
			font-size: 500px;
			font-weight: 900;
			letter-spacing: .1em;
			animation-name: loadContent;
			animation-fill-mode: forwards;
			animation-delay: 0ms;
			animation-duration: 2000ms;
			animation-timing-function: $easing-material;

			@media screen and (max-width: map-get($breakpoints, 'md')) {
				font-size: 200px;
			}

		}


		&--mounted {
			transform: translate3d(100%,0,0);
		}

		&--loading {
			transform: translate3d(0,0,0);
		}

		&-leave-active {
			transform: translate3d(-100%,0,0);
		}

		&-content {

		}

		&-load {
			position: absolute;
			top: 0%;
			left: 0%;
			//transform: translateX(-50%);
			height: 10px;
			background-color: color_('white');
			content: "";
			width: 0%;
			opacity: 1;

			animation-fill-mode: forwards;
			animation-delay: 0ms;
			animation-duration: 2000ms;
			animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
		}
	}

	@keyframes loadLine {
		0% {
			width:0%;
			// opacity: 1;
		}


		100% {
			width: 100%;
			//background-color: color_('yellow');
			// opacity: 1;
		}
	}

	@keyframes loadContent {
		0% {
			transform: translateX(-50%) translateY(0%);
			opacity: 0;
		}


		100% {
			transform: translateX(0) translateY(0);
			//background-color: color_('yellow');
			opacity: 1;
		}
	}
</style>