<template>
	<div class="view view--main main">
		<loader></loader>
		<site-nav></site-nav>
		<router-view class="main__content" name="content"></router-view>
		<router-view class="main__footer" name="footer"></router-view>
	</div>
</template>

<script>
// 	Dependents
import { mapGetters } from 'vuex'

// Components
import SiteNav from '@/components/SiteNav'
import Loader from '@/components/Loader'

// Export
export default {
	name: 'mainView',
	components: {
		SiteNav,
		Loader,
	},
	data: () => ({
		scrollPos: 0,
		ticking: false,
	}),
	mounted() {
		// if (process.BROWSER) {
			window.onload = () => {
				this.$store.dispatch('setLoading', false)
			}
			window.addEventListener('scroll', this.handleScroll, false)
			if ((/MSIE|Trident/ig).test(window.navigator.userAgent)) {
				setTimeout(() => {
					this.$store.dispatch('setLoading', false)
				}, 1000)
			}
		// }

		this.$store.dispatch('setScroll', this.scrollPos)
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll, false)
	},
	methods: {
		handleScroll() {
			this.scrollPos = window.scrollY || document.documentElement.scrollTop
			this.requestTick()
		},
		requestTick() {
			if (!this.ticking) {
				requestAnimationFrame(this.updateScroll)
				this.ticking = true
			}
		},
		updateScroll() {
			this.$store.dispatch('setScroll', this.scrollPos)
			this.ticking = false
		},
	},
	computed: {
		...mapGetters([
			'loading',
			'scroll',
		]),
	},
}
</script>

<style lang="scss" scoped>
.main {
	// display: flex;
	// flex-direction: column;
	// flex: 1;
	min-height: 100%;
	width: 100%;

	&__header {
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 10;
	}

	&__content {
		flex: 1;
		overflow-x: hidden;
	}
}
</style>
