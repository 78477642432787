<template>
	<div class="hero">
		<div class="hero__wrapper">
			<div class="hero__text">
				<h1>Mike Parsons Ink</h1>
				<span>Award Winning Tattoo Studio</span>
				<span class="address">Riverview, FL &bull; est <i>2010</i></span> 
			</div>
		</div>
	</div>
</template>

<script>
// Export
export default {
	name: 'hero',
	data: () => ({
		title: 'TITLE',
		bg: 'images/hero__bg.jpg',
	}),
	mounted() {
		if (this.$route.meta && this.$route.meta.title) {
			this.title = this.$route.meta.title
			this.bg = this.$route.meta.bg
		}
	},
	computed: {
		heroBg() {
			if (this.bg) {
				return require('@/assets/' + this.bg)
			}
		},
	},
	watch: {
		'$route'(to, from) {
			if (to.meta && to.meta.title) {
				this.title = to.meta.title
				this.bg = to.meta.bg
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.hero {
	@include ratio('hero');
	min-height: 480px;
	max-height: 1160px;

	&__wrapper {
		background: url(~@/assets/images/video-bg-home.jpg) #1b1b1b center no-repeat;
		background-size: cover;
		background-attachment: fixed;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;	

		display: flex;
		align-items: center;
		justify-content: center;

		@media screen and (max-width: map-get($breakpoints, 'md')) {
			background-attachment: scroll;
		}
	}


	&__text {
		
		text-align: center;
		opacity: 0;
		width: 100%;
		display: block;

		animation-fill-mode: forwards;
		animation-delay: 1000ms;
		animation-duration: 1000ms;
		animation-name: fadeIn;
		animation-timing-function: ease-in-out;
		backface-visibility: visible !important;


		h1 {
			color:color_('white');
			font-family: $font-primary;
			font-size:90px;
			font-weight: 700;
			text-align: center;
			line-height: 1;
			max-width: 80%;
			margin:0 auto;
			text-indent: -9999px;
			background: url('../assets/images/mikeparsonsinklogo.png') no-repeat center;
			background-size: contain;
			width: 1250px;
			height: 327px;


			@media screen and (max-width: map-get($breakpoints, 'lg')) {
				font-size:80px;
				max-width: 90%;
			}

			@media screen and (max-width: map-get($breakpoints, 'md')) {
				font-size:60px;
				max-width: 100%;
				height: 300px;
			}

			@media screen and (max-width: map-get($breakpoints, 'sm')) {
				font-size:40px;
				height: 260px;
			}

			@media screen and (max-width: map-get($breakpoints, 'xs')) {
				font-size:25px;
				height: 260px;
			}
		}

		span {
			font-family: $font-primary;
			color: color_('white');
			font-size: 26px;
			text-align: center;
			text-transform: uppercase;
    		letter-spacing: .35em;
    		position: relative;
    		top: -80px;
    		opacity: 0;
			display: block;
			font-weight: 700;

    		animation-fill-mode: forwards;
			animation-delay: 1200ms;
			animation-duration: 400ms;
			animation-name: slideUp;
			animation-timing-function: ease-in-out;
			backface-visibility: visible !important;

			@media screen and (max-width: map-get($breakpoints, 'lg')) {
				font-size: 20px;
				letter-spacing: .2em;
			}

			@media screen and (max-width: map-get($breakpoints, 'sm')) {
				font-size: 16px;
			}
		}

		.address {
			font-size: 20px;
			font-weight: 700;
			margin-top: 14px;
			line-height: 1.8;
			letter-spacing: .2em;
			//color: color_('grey');
			animation-delay: 1400ms;

			i {
				font-size: 150%;
				font-style: normal;
				font-weight: 500;
			}
		

			@media screen and (max-width: map-get($breakpoints, 'md')) {
				font-size: 16px;
				letter-spacing: .1em;
				margin-top: 0px;
			}

			@media screen and (max-width: map-get($breakpoints, 'sm')) {
				font-size: 16px;
			}
		}
	}
}
</style>
